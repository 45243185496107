import * as React from 'react';
import Grid from '../components/soul/grid/Grid';
import GridItem from '../components/soul/grid/GridItem';
import {GridDensity} from '../components/soul/grid/GridDensity';
import {GHAlignment, GVAlignment} from "../components/soul/grid/GridAlignment";
import {GridBreakpoint} from "../components/soul/grid/GridBreakpoint";
import SLink from "../components/soul/link/SLink";
import {ButtonType} from "../components/soul/link/LinkTypes";
import {GridDirection} from "../components/soul/grid/GridDirection";
import Image from '../components/Image'
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import {fullSpanOnMobile} from "../components/soul/grid/GridItemSpan";

import Logo from '../assets/img/ESTECO.inline.svg';
import Svg from "../components/Svg";
import {IGatsbyImageData} from "gatsby-plugin-image";

const GATSBY_ENV = process.env.GATSBY_ENV;

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface IndexPageProps {
    data: {
        allHomeContentJson: {
            nodes: {
                url: string,
                url_staging: string,
                title: string,
                location: string,
                label :string,
                date :string,
                logo: IGatsbyImageData
            }[]
        }
    };
}

const IndexPage: React.FC<IndexPageProps> = (props) => {
    return (
        <React.Fragment>
            <div className="soul-font-scale-m" id="home">
                <SEO/>
                <main id="js-main-content">
                    <div className='home-menu'>
                        <div className="soul-space-inset-3-xl">
                            <div className="esteco-logo-container h-text-align-center">
                                <Svg color="#FFFFFF" className="esteco-logo"><Logo /></Svg>
                            </div>
                            <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} hAlignment={GHAlignment.SPACE_AROUND} vAlignment={GVAlignment.CENTER} wrap={true} className="home-custom-grid-gap">
                                {props.data.allHomeContentJson.nodes.map(content => (
                                    <GridItem colSpan={2} responsive={[
                                        {
                                            size: 3,
                                            breakpoint: [GridBreakpoint.XL]
                                        },
                                        {
                                            size: 4,
                                            breakpoint: [GridBreakpoint.L]
                                        },
                                        {
                                            size: 6,
                                            breakpoint: [GridBreakpoint.M]
                                        },
                                        fullSpanOnMobile
                                    ]}>
                                    <Image imgObject={content.logo} className="umlogo" />
                                    <div className="soul-text-on-dark soul-font-scale-l soul-space-stack-bottom-l">
                                        <h3 dangerouslySetInnerHTML={{__html: content.title}}  className="soul-heading soul-heading--h3 soul-text-on-dark soul-space-stack-bottom-s"></h3>
                                        <p>{content.location}</p>
                                        <p className="h-text-bold">{content.date}</p>
                                    </div>
                                    <div className="soul-content soul-content--on-dark soul-font-scale-l soul-space-stack-bottom-l">
                                    </div>
                                    <SLink to={GATSBY_ENV === 'staging' ? content.url_staging : content.url} linkType={ButtonType.DEFAULT} className="soul-button--comfortable soul-button--fill">
                                        Visit website
                                    </SLink>
                                </GridItem>))}
                            </Grid>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
};

export const pageQuery = graphql`
    query HomeContent {
      allHomeContentJson {
        nodes {
          url
          url_staging
          title
          location
          label
          date
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
`;
export default IndexPage;
